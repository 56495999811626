import React, { useEffect, useState } from 'react'
import { Dropdown, Button } from 'antd'
import _ from 'lodash'

import CoreRenderFunction from 'DisplayCore'
import { applyColor } from 'utils/apply-color'
import { getContraColor, getSelectedLanguageContent } from 'utils'

const DropDownButton = props => {
  let {
    param,
    style,
    children,
    menuProps,
    actionKey,
    placement,
    trigger
  } = props.item

  const [ dropDownMenu, setDropDownMenu ] = useState([])

  useEffect(() => {
    setDropDownMenu({ 
      items: processMenu( menuProps ) || [], 
      onClick: e => {
        if ( typeof props.onClickAction === 'function' ) {
          props.onClickAction( actionKey, e, ...( param ? param.map( val => props[ val ] || val ) : [] ) )
        }
      }})
  }, [ menuProps ])
  
  const processMenu = menuItems => {
    return _.filter( menuItems.map( menuItem => {
      if( typeof menuItem.hide === 'function' && menuItem.hide( props ) ){
        return null
      } else {
        if( !_.isEmpty( menuItem.icon ) ){
          return({
            ...menuItem,
            label: getSelectedLanguageContent( menuItem.label, props.selectedLanguage ),
            icon: <CoreRenderFunction {...props} item={ menuItem.icon } />
          })
        } else {
          return ({
            ...menuItem,
            label: getSelectedLanguageContent( menuItem.label, props.selectedLanguage )
          })
        }
      }
    }), item => item !== null )
  }

  if(dropDownMenu?.items?.length === 0){
    return <></>
  }
  return(
    <Dropdown
      style={{
        ...applyColor( style, props.colors ),
        color: applyColor( style?.color || getContraColor( applyColor( style?.backgroundColor, props.color ) ), props.colors )
      }}
      menu={ dropDownMenu }
      placement={ placement }
      trigger={ trigger }>
      <Button>
        {
          children?.map( child => <CoreRenderFunction {...props} item={ child } />)
        }
      </Button>
    </Dropdown>
  )
}

export default DropDownButton