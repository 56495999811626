import React, { useEffect, useState } from 'react'
import { LineChart, Line, CartesianGrid, ResponsiveContainer, XAxis, YAxis, Tooltip } from 'recharts'
import { handleNestedObjectKey } from "utils";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import { applyColor } from 'utils/apply-color'

const TechBaseChartContent = props => {
  let {
    lines,
    width,
    height,
    dataKey,
    config,
    loading
  } = props.item

  const [data, updateData] = useState([])

  useEffect(() => {
    if (props[dataKey]) {
      updateData(props[dataKey])
    } else {
      updateData([])
    }
  }, [props[dataKey]])

  if(loading && handleNestedObjectKey(props, loading)){
    return <div className='d-flex justify-content-center align-items-center'><Spin indicator={<LoadingOutlined spin />} size="large" /></div>
  }
  
  return(
    <ResponsiveContainer width={ width } height={ height }>
      <LineChart { ...config.chart } data={ data }>
        <CartesianGrid { ...config.chart?.cartesianGrid } />
        <XAxis { ...config.chart?.xAxis } />
        <YAxis { ...config.chart?.yAxis }/>
        <Tooltip { ...config.chart?.tooltip } />
        {
          lines?.map( line => (
            <Line { ...applyColor( line, props.colors ) } />
          ))
        }
      </LineChart>
    </ResponsiveContainer>
  )
}

export default TechBaseChartContent
