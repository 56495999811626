import _ from "lodash"

export const MainNav = [
  {
    module_name: "Dashboard",
    icon: "pe-7s-home",
    label: 'DASHBOARD',
    to: "#/dashboard",
  },
  {
    module_name: "Users",
    icon: "pe-7s-users",
    label: 'USERS',
    content: [
      {
        module_name: "Profile",
        icon: "pe-7s-user",
        label: 'PROFILE',
        to: "#/dashboard/update-profile",
      },
      {
        module_name: "Member List",
        icon: "pe-7s-users",
        label: 'MEMBER_LIST',
        to: "#/dashboard/member-list",
      },
      {
        module_name: "Download CP58",
        icon: "pe-7s-cloud-download",
        label: "DOWNLOAD_CP58",
        to: "#/dashboard/download-cp58",
      }
    ],
  },
  // {
  //   icon: "pe-7s-door-lock",
  //   label: ACCESS_RIGHT,
  //   to: "#/dashboard/access-right"
  // },
  {
    module_name: "System Settings",
    icon: "pe-7s-tools",
    label: 'SYSTEM_SETTINGS',
    content: [
      // {
      //   icon: "pe-7s-cash",
      //   label: WALLETS,
      //   to: "#/dashboard/wallets-settings"
      // },
      {
        module_name: "Shipment Zones and Pricings",
        icon: "pe-7s-settings",
        label: 'SHIPMENT_ZONE_AND_PRICINGS',
        to: "#/dashboard/shipment-zone-and-pricings",
      },
      {
        module_name: "Banner List",
        icon: "pe-7s-photo",
        label: 'BANNER_LIST',
        to: "#/dashboard/banner-list",
      },
    ],
  },
  {
    module_name: "Products Management",
    icon: "pe-7s-portfolio",
    label: 'PRODUCTS_MANAGEMENT',
    content: [
      {
        icon: "pe-7s-shopbag",
        module_name: "Products Per Group",
        label: 'PRODUCTS_PER_GROUP',
        to: "#/dashboard/products-management",
      },
      {
        icon: 'pe-7s-shopbag',
        module_name: "Product List",
        label: 'PRODUCT_LIST',
        to: '#/dashboard/products-list',
      },
      {
        icon: 'pe-7s-shopbag',
        module_name: "Stock List From PC",
        label: 'STOCK_LIST',
        to: '#/dashboard/stocks-list',
      },
      {
        icon: "pe-7s-folder",
        module_name: "Products Category",
        label: 'PRODUCTS_CATEGORY',
        to: "#/dashboard/products-category",
      },
      {
        icon: "pe-7s-ticket",
        module_name: "Products Tags",
        label: 'PRODUCTS_TAGS',
        to: "#/dashboard/products-tags",
      },
      {
        icon: "pe-7s-box2",
        module_name: "Packages",
        label: 'PACKAGES',
        to: "#/dashboard/packages-management",
      },
      {
        icon: "pe-7s-ribbon",
        module_name: "Checkout Codes",
        label: 'CHECKOUT_CODES',
        to: "#/dashboard/checkout-code",
      }
    ],
  },
  {
    module_name: "Promotions",
    icon: "pe-7s-shopbag",
    label: 'PROMOTIONS',
    content: [
      {
        module_name: "Discounts",
        icon: "pe-7s-shopbag",
        label: 'DISCOUNTS',
        to: "#/dashboard/promotions/discounts",
      },
      {
        module_name: "PWP",
        icon: "pe-7s-shopbag",
        label: 'PWP',
        to: "#/dashboard/promotions/pwp",
      },
      {
        module_name: "GWP",
        icon: "pe-7s-shopbag",
        label: 'GWP',
        to: "#/dashboard/promotions/gwp",
      },
      {
        module_name: "Vouchers",
        icon: "pe-7s-shopbag",
        label: 'PROMO_CODE',
        to: "#/dashboard/promotions/vouchers",
      },
      {
        module_name: "Promo Groups",
        icon: "pe-7s-folder",
        label: 'PROMO_GROUPS',
        to: "#/dashboard/promotions/promoGroups",
      }
    ]
  },
  {
    module_name: "Voucher Management",
    icon: "pe-7s-shopbag",
    label: 'VOUCHER_MANAGEMENT',
    to: "#/dashboard/voucher",
  },
  {
    module_name: "Warehouses Management",
    icon: "pe-7s-box1",
    label: 'WAREHOUSES_MANAGEMENT',
    content: [
      {
        module_name: "Warehouses",
        icon: "pe-7s-box1",
        label: 'WAREHOUSES',
        to: "#/dashboard/warehouses-management/warehouses",
      },
      {
        module_name: "Assign Child Warehouses",
        icon: "pe-7s-box1",
        label: 'ASSIGN_CHILD_WAREHOUSES',
        to: "#/dashboard/warehouses-management/assign-child-warehouses",
      },
    ],
  },
  {
    module_name: "Shipment",
    icon: "pe-7s-car",
    label: 'SHIPMENT',
    content: [
      {
        module_name: "Dispense List",
        icon: "pe-7s-box2",
        label: 'DISPENSE_LIST',
        to: "#/dashboard/dispense-list",
      },
      {
        module_name: "Self Collection List",
        icon: "pe-7s-note2",
        label: 'SELF_COLLECTION_LIST',
        to: "#/dashboard/self-collection-list",
      },
      {
        module_name: "Shipping List",
        icon: "pe-7s-note2",
        label: 'SHIPPING_LIST',
        to: "#/dashboard/shipping-list",
      },
    ],
  },
  {
    module_name: "Inventory",
    icon: "pe-7s-box1",
    label: 'INVENTORY',
    content: [
      {
        icon: "pe-7s-plugin",
        module_name: "Stock Adjustment List",
        label: 'STOCK_ADJUSTMENT_LIST',
        to: "#/dashboard/stock-adjustment-list"
      },
      {
        icon: "pe-7s-note2",
        module_name: "Stock Adjustment Report",
        label: 'STOCK_ADJUSTMENT_REPORT',
        to: "#/dashboard/stock-adjustment-report"
      },
      {
        icon: "pe-7s-shuffle",
        module_name: "Inventory Perpetual List",
        label: 'INVENTORY_PERPETUAL_LIST',
        to: "#/dashboard/inventory-perpetual-list"
      }
    ]
  },
  {
    module_name: "Reports",
    icon: "pe-7s-note2",
    label: 'REPORTS',
    content: [
      {
        module_name: 'Commissions Detail Report',
        icon: 'pe-7s-news-paper',
        label: 'COMMISSION_DETAIL_REPORT',
        to: '#/dashboard/commissions-detail-report',
      },
      {
        module_name: 'Commissions Report',
        icon: 'pe-7s-news-paper',
        label: 'COMMISSIONS_REPORT',
        content: [
          {
            module_name: 'Bonus Summary',
            icon: 'pe-7s-news-paper',
            label: 'BONUS_SUMMARY',
            to: '#/dashboard/bonus-summary',
          },
          {
            module_name: 'Daily Paired History',
            icon: 'pe-7s-news-paper',
            label: 'DAILY_PAIRED_HISTORY',
            to: '#/dashboard/daily-paired-history',
          },
          {
            module_name: 'Monthly Paired History',
            icon: 'pe-7s-news-paper',
            label: 'MONTHLY_PAIRED_HISTORY',
            to: '#/dashboard/monthly-paired-history',
          },
          {
            module_name: 'Pairing Daily Balance',
            icon: 'pe-7s-news-paper',
            label: 'PAIRING_DAILY_BALANCE',
            to: '#/dashboard/pairing-daily-balance',
          },
          {
            module_name: 'Sales SV To Maintenance',
            icon: 'pe-7s-news-paper',
            label: 'SALES_SV_TO_MAINTENCE',
            to: '#/dashboard/sales-to-maintence',
          },
          {
            module_name: 'Upgrade Package Rank History',
            icon: 'pe-7s-news-paper',
            label: 'UPGRADE_PACKAGE_RANK_HISTORY',
            to: '#/dashboard/upgrade-package-rank-history',
          },
          {
            module_name: 'Upgrade Rank History',
            icon: 'pe-7s-news-paper',
            label: 'UPGRADE_RANK_HISTORY',
            to: '#/dashboard/upgrade-rank-history',
          },
          {
            module_name: 'SV Balance',
            icon: 'pe-7s-news-paper',
            label: 'SV_BALANCE',
            to: '#/dashboard/sv-balance',
          },
          {
            module_name: 'All Time Sales SV',
            icon: 'pe-7s-news-paper',
            label: 'ALL_TIMES_SALES_SV',
            to: '#/dashboard/all-times-sales',
          },
          {
            module_name: 'Placement Down Pair Details',
            icon: 'pe-7s-news-paper',
            label: 'PLACEMENT_DOWN_PAIR_DETAILS',
            to: '#/dashboard/placement-down-pair-details',
          }
        ]
      },
      {
        module_name: "Commission Summary Report",
        icon: "pe-7s-file",
        label: 'COMMISSION_SUMMARY_REPORT',
        to: "#/dashboard/commission-summary",
      },
      {
        module_name: "Sales Summary Report",
        icon: "pe-7s-news-paper",
        label: 'SALES_SUMMARY_REPORT',
        to: "#/dashboard/sales-summary-report",
      },
      {
        module_name: "Sales Report",
        icon: "pe-7s-news-paper",
        label: 'SALES_REPORT',
        to: "#/dashboard/sales-report",
      },
      {
        module_name: "Sales Detail Report",
        icon: "pe-7s-news-paper",
        label: 'SALES_DETAIL_REPORT',
        to: "#/dashboard/sales-detail-report",
      },
      {
        module_name: "Non Sales Report",
        icon: "pe-7s-news-paper",
        label: 'NON_SALES_REPORT',
        to: "#/dashboard/non-sales-report",
      },
    ],
  },
  {
    module_name: "Transactions Approval",
    icon: "pe-7s-cash",
    label: 'TRANSACTION_APPROVAL',
    to: "#/dashboard/transaction-approval",
  },
  {
    module_name: "Wallets",
    icon: "pe-7s-wallet",
    label: 'WALLETS',
    content: [
      {
        module_name: "Wallet Statement",
        icon: "pe-7s-wallet",
        label: 'WALLET_STATEMENT',
        to: "#/dashboard/wallets",
      },
      {
        module_name: "Reload",
        icon: "pe-7s-plus",
        label: 'RELOAD',
        to: "#/dashboard/wallet-topup",
      },
      {
        module_name: "Transfer",
        icon: "pe-7s-next-2",
        label: 'TRANSFER',
        to: "#/dashboard/wallet-transfer",
      },
      {
        module_name: "Convert",
        icon: "pe-7s-repeat",
        label: 'CONVERT',
        to: "#/dashboard/wallet-convert",
      },
      {
        module_name: "Withdrawal",
        icon: "pe-7s-cash",
        label: 'WITHDRAWAL',
        to: "#/dashboard/wallet-withdrawal",
      },
    ],
  },
  {
    module_name: "Mall",
    icon: "pe-7s-shopbag",
    label: 'MALL',
    content: [
      {
        module_name: "Shopping Mall",
        icon: "pe-7s-shopbag",
        label: 'SHOPPING_MALL',
        to: "#/dashboard/mall",
      },
      {
        module_name: "Redemption Mall",
        icon: "pe-7s-shopbag",
        label: 'REDEMPTION_MALL',
        to: "#/dashboard/redemption/mall",
      },
      {
        module_name: "Upgrade Mall",
        icon: "pe-7s-shopbag",
        label: 'UPGRADE_MALL',
        to: "#/dashboard/upgrade/mall",
      }
    ]
  },
  {
    module_name: "Transfer Orders",
    icon: "pe-7s-note",
    label: 'TRANSFER_ORDERS',
    to: "#/dashboard/transfer-orders"
  },
  {
    module_name: "Orders",
    icon: "pe-7s-note2",
    label: 'ORDERS',
    to: "#/dashboard/orders",
  },
  {
    module_name: "CP58",
    icon: "pe-7s-print",
    label: "CP58",
    content: [
      {
        module_name: "Download CP58",
        icon: "pe-7s-cloud-download",
        label: "DOWNLOAD_CP58",
        to: "#/dashboard/download-cp58",
      },
      {
        module_name: "Offline Reward",
        icon: "pe-7s-cash",
        label: "MEMBER_OFFLINE_REWARD",
        to: "#/dashboard/member-offline-rewards",
      }
    ],
  },
  {
    module_name: "Organisation Chart",
    icon: "pe-7s-culture",
    label: 'ORGANISATION_CHART',
    content: [
      {
        module_name: "Sponsor Tree",
        icon: "pe-7s-network",
        label: 'SPONSOR_TREE',
        to: "#/dashboard/sponsor_tree",
      },
      {
        module_name: "Placement Tree",
        icon: "pe-7s-network",
        label: 'PLACEMENT_TREE',
        to: "#/dashboard/placement_tree",
      }
    ],
  },
  {
    module_name: "Announcements",
    icon: "pe-7s-speaker",
    label: 'ANNOUNCEMENT',
    content: [
      {
        icon: "pe-7s-speaker",
        label: 'ANNOUNCEMENT_LIST',
        to: "#/dashboard/announcements",
      },
    ],
  },
  {
    module_name: 'Staff Settings',
    icon: 'pe-7s-users',
    label: 'STAFF_SETTINGS',
    content: [
      {
        module_name: 'Roles and Permission',
        icon: 'pe-7s-door-lock',
        label: 'ROLE_PERMISSION',
        to: '#/dashboard/role_permission',
      },
      {
        module_name: 'Subadmin List',
        icon: 'pe-7s-users',
        label: 'SUBADMIN_LIST',
        to: '#/dashboard/subadmin-list',
      },
      {
        module_name: 'Non Sales Type',
        icon: 'pe-7s-box1',
        label: 'NON_SALES_TYPE',
        to: '#/dashboard/non-sales-type',
      },
      {
        module_name: "Company Information",
        icon: "pe-7s-safe",
        label: "COMPANY_INFORMATION",
        to: "#/dashboard/company-information",
      }
    ],
  },  
  {
    module_name: 'Contact Us',
    icon: 'pe-7s-headphones',
    label: 'CONTACT_US',
    to: '#/dashboard/contact-us'
  }
]
